<template>
  <div :class="layoutPcMi ? 'good-detail' : 'good-detail-ml'">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>
      <el-breadcrumb-item>{{ detail.name }}</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div v-if="layoutPcMi">
      <div class="classification-location">
        <div>您的当前位置:</div>
        <div @click="to('/')">首页</div>
        <div>|</div>
        <div>{{ detail.name }}</div>
      </div>
      <div class="detail-content">
        <div class="con-left">
          <div style="position: relative">
            <div class="left" :style="!layoutPcMi ? 'width: unset;' : ''">
              <img
                class="leftImg"
                :style="layoutPcMi ? '' : 'width: 100%;'"
                :src="banner[bannerIndex]"
                alt=""
              />
              <!-- 鼠标层罩 -->
              <div v-show="topShow" class="top" :style="topStyle"></div>
              <!-- 最顶层覆盖了整个原图空间的透明层罩 -->
              <div
                class="maskTop"
                v-if="layoutPcMi"
                @mouseenter="enterHandler"
                @mousemove="moveHandler"
                @mouseout="outHandler"
              ></div>
            </div>
            <div v-show="rShow" class="right">
              <img
                :style="r_img"
                class="rightImg"
                :src="banner[bannerIndex]"
                alt=""
              />
            </div>
          </div>
          <vue-photo-zoom-pro>
            <!--          <div class="img">-->
            <!--              <img class="image" src="../../assets/logo.png" alt="">-->
            <!--          </div>-->
          </vue-photo-zoom-pro>
          <div class="detail-img-list">
            <div
              @click="bannerIndex = index"
              v-for="(item, index) in banner"
              :key="index"
            >
              <img style="width: 100%; height: 100%" :src="item" alt="" />
            </div>
          </div>
        </div>

        <div class="con-right" v-if="false">
          <div class="title">{{ detail.name }}</div>
          <div style="display: flex; margin: 10px 0; font-size: 13px">
            <div style="margin-right: 20px">
              <!-- <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i> -->
              <el-rate
                disabled-void-color="#d2d2d2"
                :value="detail.star > 5 ? 5 : detail.star"
                disabled
              ></el-rate>
            </div>
            <div>0 评论/发表评论</div>
          </div>
          <div class="detail-price">
            <div>￥ {{ detail.price }}</div>
            <div>￥ {{ detail.origin }}</div>
          </div>
          <el-divider></el-divider>
          <div style="display: flex">
            <div>
              <p class="shop-info">
                <span>型号:</span>
                {{ detail.code || "-" }}
              </p>
              <p class="shop-info">
                <span>商品库存:</span>
                {{ detail.stock }}
              </p>
              <p class="shop-info">
                <span>数量:</span>
                <el-input-number
                  style="margin-right: 10px; width: 100px"
                  size="mini"
                  v-model="goodNum"
                ></el-input-number>
                件
              </p>
            </div>
          </div>
          <!-- <el-divider></el-divider> -->
          <div style="display: flex">
            <!-- <el-input v-model="goodNum" type="number"></el-input> -->
            <div class="product-pay">
              <el-button @click="pay">立即购买</el-button>
            </div>
            <div class="product-num">
              <el-button style="margin-left: 10px"
                ><i
                  class="el-icon-shopping-cart-full"
                  style="margin-right: 10px"
                ></i
                >加入购物车</el-button
              >
            </div>

            <!-- <div @click="createOrder">去结账</div> -->
            <div>
              <i></i>
            </div>
            <div>
              <i></i>
            </div>
          </div>
        </div>

        <div class="detail-right">
          <div class="detail-name">{{ detail.name }}</div>
          <!-- <div class="detail-desc">
          容量：380ml 内胆材质：304奥氏体不锈钢（06Cr19Ni10）
          外壳材质：304奥氏体不锈钢（06Cr19Ni10） 保温效能：6小时≥42℃
          装箱数：24pcs 产品特点 1.微波镀铜内胆，锁住温度，长效保温；
          2.隐藏式硅胶伸缩拎绳，随身出行； 3.食品用pp过滤茶隔，贴心防烫，无BPA；
          4.防滑静音硅胶底垫，抗震防摔。 颜色：Q6382蓝色、P7069红色
        </div> -->
          <div style="margin-top: 20px" class="product-price">
            <div class="product-1">
              <div>产品编号</div>
              <span>{{ detail.goods_no || "-" }}</span>
            </div>
            <div class="product-1">
              <div>产品品牌</div>
              <span class="product-name1">{{ detail.brand_name }}</span>
            </div>
            <div class="product-1">
              <div>商城价</div>
              <span class="product-name2"
                >￥ {{ detail.spec && detail.spec.price.toFixed(1) }}</span
              >
            </div>
            <div class="product-1">
              <div>市场价</div>
              <span class="product-name3"
                >￥ {{ detail.spec && detail.spec.origin }}</span
              >
            </div>
          </div>
          <!-- <div class="product-contact">
            <div>获取优惠价格，请 在线 <a href=""> 咨询客服</a>客服</div>
            <div>或拨打{{ lesseeMsg.market_phone }}</div>
          </div>
          <div class="product-hint">
            温馨提示：礼品采购会员如需添加意向礼品生成PPT方案，可联系客服开通权限。（开通权限-重新登录-添加PPT-制作PPT-生成下载PPT）
          </div> -->
          <!-- <div class="skip-wx">
            <div class="skip-item">
              <div>微信公众号</div>
              <img
                src="https://smj2.51js.net.cn/jeupload/202209/9Le2FOUQCnb80stl44U.png"
                alt=""
              />
            </div>
            <div class="skip-item">
              <div>小程序</div>
              <img
                src="https://smj2.51js.net.cn/jeupload/202209/9Le2FOUQCnb80stl44U.png"
                alt=""
              />
            </div>
            <div class="skip-item">
              <div>手机直达</div>
              <img
                src="https://smj2.51js.net.cn/jeupload/202209/9Le2FOUQCnb80stl44U.png"
                alt=""
              />
            </div>
          </div> -->
        </div>

        <!-- <div class="">
       
      </div> -->
      </div>

      <div class="detail-footer">
        <div class="left-list">
          <img
            src="http://shop.shangjiu-gift.com.cn/Templates/14/images/lpfl.jpg"
            alt=""
          />
          <div
            v-for="(item, index) in classificationList"
            :key="index"
            class="left-item"
            @click="to('/classificationGoods')"
          >
            <div class="classify-title">
              <img
                src="http://cdn.weilf.cn/Templates/14/images/product-12.png"
                alt=""
              />
              <div>{{ item.name }}</div>
            </div>
            <div
              @click.stop="to('/classificationGoods')"
              v-for="(item2, index2) in item.child"
              class="classify-child"
            >
              <div>> {{ item2.name }}</div>
            </div>
          </div>
        </div>
        <div class="product-detail">
          <div class="product-detail-title">产品详情</div>
          <img
            style="width: 100%"
            v-for="item in detail.details_imgs"
            :src="item"
            alt=""
          />
        </div>
      </div>
      <div v-if="false" class="detail-bottom-good">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="商品详情" name="goodDetail">
            <!-- <div>
            {{ goodDetailText }}
          </div> -->
            <img
              style="width: 100%"
              v-for="item in detail.details_imgs"
              :src="item"
              alt=""
            />
          </el-tab-pane>
          <el-tab-pane label="商品评价" name="goodEvaluation">
            <div>
              <div v-if="goodEvaluation.length != 0">
                <div v-for="(item, i) of goodEvaluation" :key="i">a</div>
              </div>
              <div v-else>此商品暂无评论。</div>
              <div>
                <h4>发表评论</h4>
                <el-form>
                  <el-form-item label="您的姓名">
                    <el-input></el-input>
                  </el-form-item>
                  <el-form-item label="评论内容">
                    <el-input></el-input>
                  </el-form-item>
                  <el-form-item label="评分"> 差评 </el-form-item>
                  <el-button>提交</el-button>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-else>
      <navbar-header></navbar-header>
      <div class="h5-container">
        <div class="h5-breadcrumb">
          <van-icon @click="to('/')" name="wap-home" />
          <div>/</div>
          <div>{{ detail.name }}</div>
        </div>
        <van-swipe class="h5-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in detail.attachs" :key="index">
            <img :src="item" alt="" />
          </van-swipe-item>
        </van-swipe>

        <div class="h5-procuct-name">{{ detail.name }}</div>
        <div class="product-price-wrapper">
          <div>¥ {{ detail.spec && detail.spec.price }}</div>
          <div>¥ {{ detail.spec && detail.spec.origin }}</div>
        </div>
        <div class="h5-procuct-content">
          <div><span>品牌：</span>{{ detail.brand_name }}</div>
          <div><span>型号：</span>{{ detail.spec && detail.spec.name }}</div>
          <div>
            <span>商品库存：</span>{{ detail.spec && detail.spec.stock_num }}
          </div>
        </div>

        <div class="h5-product-detail">
          <div>商品详情</div>
          <img
            v-for="(item, index) in detail.details_imgs"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { http } from "../../../request/http";
import navbarHeader from "../../components/navbarHeader.vue";
export default {
  name: "goodDetail",
  components: {
    vuePhotoZoomPro,
    navbarHeader,
  },
  data() {
    return {
      layoutPcMi: this.$store.state.layoutPcMi,
      goodDetailText: "商品详情内容",
      goodNum: 1,
      goodEvaluation: [],
      activeName: "goodDetail",
      topStyle: { transform: "" },
      r_img: {},
      topShow: false,
      rShow: false,
      detail: {},
      banner: [],
      bannerIndex: 0,
      classificationList: [],
      lesseeMsg: "",
    };
  },
  mounted() {
    this.queryDetail();
    this.queryClassify();
    if (JSON.parse(localStorage.getItem("lesseeMsg"))) {
      this.lesseeMsg = JSON.parse(localStorage.getItem("lesseeMsg"));
    } else {
      setTimeout(() => {
        this.lesseeMsg = JSON.parse(localStorage.getItem("lesseeMsg"));
      }, 1000);
    }
  },
  methods: {
    to(url) {
      this.$router.push(url);
    },
    // 获取商品分类
    queryClassify() {
      let that = this;
      that.classificationList = [];

      http("goods.php", {
        fun: "getGoodsType",
      }).then((res) => {
        if (res.code == 0) {
          that.classificationList = res.data;
          // if (id) {
          //   that.queryClassShop(id);
          // } else {
          //   that.queryClassShop(list[0].id);
          // }
        }
      });
    },
    // 鼠标进入原图空间函数
    enterHandler() {
      // 层罩及放大空间的显示
      this.topShow = true;
      this.rShow = true;
    },
    // 鼠标移动函数
    moveHandler(event) {
      // 鼠标的坐标位置
      const x = event.offsetX;
      const y = event.offsetY;
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 100 < 0 ? 0 : x - 100;
      let topY = y - 100 < 0 ? 0 : y - 100;
      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 200) topX = 200;
      if (topY > 200) topY = 200;
      // 通过 transform 进行移动控制
      this.topStyle.transform = `translate(${topX}px, ${topY}px)`;
      this.r_img.transform = `translate(-${2 * topX}px, -${2 * topY}px)`;
    },
    // 鼠标移出函数
    outHandler() {
      // 控制层罩与放大空间的隐藏
      this.topShow = false;
      this.rShow = false;
    },
    handleClick() {},
    queryDetail() {
      let id = this.$route.query.id;
      http("goods.php", {
        fun: "getGoodsEx",
        goods_dbids: id,
      }).then((res) => {
        if (res.code == 0) {
          this.detail = res.data[0];
          this.banner = this.detail.attachs;
          // this.banner = [
          //   "http://cdn.weilf.cn/Contents/site1/202209/7ebfeac125394565aae4c8c1fdaca239.jpg",
          // ];
        }
      });
    },
    createOrder() {
      //  http('order.php', {
      // 				fun: 'createShopOrder',
      // 				goods: JSON.stringify(this.goods)
      // 			}).then(res => {
      // 	  })
    },
    pay() {
      this.$router.push(
        `/invoicing?shopid=${this.detail.id}&shopname=${this.detail.name}&shopnum=${this.goodNum}`
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/index.scss";
.good-detail {
  .classification-location {
    display: flex;
    font-size: 11px;
    margin: 23px 0 8px;
    border-bottom: 1px solid #c51323;
    padding-bottom: 9px;
    div {
      &:nth-child(2) {
        margin-left: 10px;
        cursor: pointer;
      }
      &:nth-child(3) {
        margin: 0 10px;
      }
      &:nth-child(4) {
        color: #757373;
      }
    }
  }
  @include body;
  width: 1200px;
  margin: 0 auto;
  .detail-content {
    margin-top: 20px;
    display: flex;
    .con-left {
      // width: 40%;
      display: flex;
      flex-direction: column;
      width: 420px;
      // height: 420px;
      .detail-img-list {
        display: flex;
        margin-top: 20px;
        border: 1px solid #dcdcdc;
        padding: 10px;
        div {
          // margin-right: 20px;
          // width: 60px;
          // height: 60px;
          // padding: 10px;
          // border: 1px solid #99a9bf;
          // border-radius: 10px;
          // @include flexCenter;
          // background-color: darken(#f2f2f4, 10%);
          width: 64px;
          height: 64px;
          img {
            width: 100%;
          }
        }
      }
      .img {
        width: 100%;
        padding: 10vh 0;
        background-color: #f1f2f5;
        @include flexCenter;
        img {
          width: 60%;
        }
      }
    }
    .con-right {
      width: 60%;
      margin-left: 40px;
      .title {
        font-weight: bold;
        font-size: 20px;
      }
      .detail-price {
        display: flex;
        align-items: flex-end;
        div {
          &:first-child {
            color: #f30240;
            font-size: 25px;
            font-weight: bold;
          }
          &:last-child {
            text-decoration: line-through;
            color: #888;
            font-size: 22px;
            margin-left: 20px;
          }
        }
      }
      .shop-info {
        font-size: 14px;
        color: #6c6c6c;
        span {
          display: inline-block;
          width: 90px;
        }
      }
      .product-pay {
        :deep(.el-button) {
          background: rgb(255, 218, 0);
          color: #fff;
        }
      }
      .product-num {
        display: flex;
        align-items: center;
        :deep(.el-button) {
          background: #f30240;
          color: #fff;
        }
      }
    }
    .detail-right {
      width: 503px;
      margin-left: 10px;
      .detail-name {
        color: #666;
        font-weight: bold;
        font-size: 14px;
      }
      .detail-desc {
        color: #999;
        font-size: 12px;
        margin-top: 8px;
      }
      .product-price {
        background: #f2f2f2;
        font-size: 14px;
        padding: 10px 0;
        padding-left: 40px;

        .product-1 {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .product-name1 {
            color: #757373;
          }
          .product-name2 {
            color: #c51323;
          }
          .product-name3 {
            color: #999;
            text-decoration: line-through;
          }
          &:last-child {
            margin-bottom: 0;
          }
          div {
            color: #999;
            font-size: 12px;
            text-align: right;
            width: 70px;
            margin-right: 38px;
          }
          span {
            font-weight: bold;
          }
        }
      }
      .product-contact {
        text-align: center;
        font-size: 12px;
        margin-top: 20px;
      }
      .product-hint {
        font-size: 12px;
        color: red;
        font-weight: bold;
      }
      .skip-wx {
        display: flex;
        margin-top: 10px;
        .skip-item {
          margin-right: 10px;
          text-align: center;
          font-weight: bold;
          div {
            margin-bottom: 5px;
          }
          img {
            width: 140px;
            height: 140px;
          }
        }
      }
    }
  }
  .detail-footer {
    margin-top: 20px;
    display: flex;

    .left-list {
      border: 1px solid #ccc;
      width: 204px;
      margin-right: 10px;
      .left-item {
        padding: 0 15px;

        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        .classify-title {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #666;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
          img {
            min-width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
        .classify-child {
          border-bottom: 1px dashed #dcdcdc;
          div {
            font-size: 12px;
            cursor: pointer;
            color: #757373;
            margin-left: 20px;
            padding: 6px 0;

            &:hover {
              color: rgb(197, 19, 35);
            }
          }
        }
      }
    }

    .product-detail {
      width: 773px;
      .product-detail-title {
        background: #c51323;
        color: #fff;
        width: 193px;
        height: 38px;
        line-height: 38px;
        text-align: center;
      }
    }
  }
}
.good-detail-ml {
  padding-top: 0;
  width: 100%;
  @extend .good-detail;
  .detail-content {
    flex-wrap: wrap;
    .con-left {
      width: 100%;
    }
    .con-right {
      width: 100%;
      margin-left: unset;
      margin-top: 20px;
    }
  }
}
.h5-container {
  padding: 0 15px;
  .h5-breadcrumb {
    display: flex;
    font-size: 13px;
    margin-bottom: 20px;
    div {
      &:nth-child(2) {
        margin: 0 10px;
      }
      &:last-child {
        color: #f30240;
      }
    }
  }
  .h5-swipe {
    img {
      width: 100%;
      height: 345px;
    }
  }
  .h5-procuct-name {
    font-weight: bold;
    margin-top: 10px;
  }
  .product-price-wrapper {
    display: flex;
    align-items: flex-end;
    margin-top: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    div {
      &:first-child {
        font-size: 26px;
        color: red;
        font-weight: bold;
      }
      &:last-child {
        font-size: 18px;
        color: #888;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
  }
  .h5-procuct-content {
    font-size: 13px;
    padding: 18px 0;
    div {
      margin-bottom: 7px;
      span {
        display: inline-block;
        width: 100px;
        color: #777;
      }
    }
  }
  .h5-product-detail {
    div {
      margin-bottom: 10px;
      background: #c51323;
      text-align: center;
      color: #fff;
      padding: 5px 0;
    }
    img {
      width: 100%;
      vertical-align: middle;
    }
  }
}
</style>
<style scoped>
/* 放大的图片，通过定位将左上角定位到(0,0) */
.rightImg {
  display: inline-block;
  width: 800px;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
}
/* 右边的区域图片放大空间 */
.right {
  margin-left: 412px;
  width: 400px;
  height: 400px;
  position: absolute;
  right: -300px;
  z-index: 10086;
  overflow: hidden;
}
/* 一个最高层层罩 */
.maskTop {
  width: 400px;
  height: 400px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
/* 层罩，通过定位将左上角定位到(0,0) */
.top {
  width: 200px;
  height: 200px;
  background-color: white;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
}
/* 原图的显示 */
.leftImg {
  width: 400px;
  height: 400px;
  display: inline-block;
}
/* 原图的容器 */
.left {
  width: 400px;
  height: 400px;
  float: left;
  position: relative;
}
</style>
